var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"registration-user-container pt-10 px-3",attrs:{"fluid":""}},[_c('CategoryTitle',{staticClass:"mt-0 my-lg-6",attrs:{"category":_vm.category}}),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.$t('register.user.description'))}}),_c('v-row',{staticClass:"mt-0 mt-sm-8",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 pr-sm-3 mt-8 mt-sm-0",attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"d-flex flex-column pa-5 pa-md-10",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("register.user.cardYes.title")))]),_c('v-card-text',{staticClass:"text-body-0 primary--text font-weight-normal"},[_vm._v(_vm._s(_vm.$t("register.user.cardYes.text")))]),_c('div',[_c('v-img',{staticClass:"my-4 tigros-card-img mx-auto",attrs:{"src":"/img_layout/card.png","contain":""}})],1),_c('v-btn',{staticClass:"mt-auto",attrs:{"color":"primary","to":{
            name: 'RegistrationCard',
            params: {
              isRegisterWithCard: true
            }
          }},domProps:{"innerHTML":_vm._s(_vm.$t('register.user.cardYes.button'))}})],1)],1),_c('v-col',{staticClass:"pa-0 pl-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"d-flex flex-column pa-5 pa-md-10 mt-8 mb-12 my-sm-0",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("register.user.cardNo.title")))]),_c('v-card-text',{staticClass:"text-body-0 primary--text font-weight-normal"},[_vm._v(_vm._s(_vm.$t("register.user.cardNo.text")))]),_c('v-btn',{staticClass:"d-flex flex-row mb-4",attrs:{"block":"","x-large":"","outlined":"","depressed":""},on:{"click":_vm.googleLogin}},[_c('v-img',{attrs:{"height":"20","max-width":"20","alt":"logo-google","src":"/img_layout/logo-google.svg"}}),_c('span',{staticClass:"primary--text ml-2 text-uppercase"},[_vm._v("Google")])],1),_c('div',{staticClass:"separator"},[_c('span',{staticClass:"line"}),_c('span',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.$t('common.or'))}}),_c('span',{staticClass:"line"})]),_c('v-btn',{staticClass:"mt-auto",attrs:{"color":"primary","to":{
            name: 'RegistrationNoCard',
            params: { type: 'NewUser', isRegisterWithCard: false }
          }},domProps:{"innerHTML":_vm._s(_vm.$t('register.user.cardNo.button'))}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }