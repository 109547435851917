<template>
  <v-container fluid class="registration-user-container pt-10 px-3">
    <CategoryTitle :category="category" class="mt-0 my-lg-6" />
    <div class="description" v-html="$t('register.user.description')" />
    <v-row no-gutters class="mt-0 mt-sm-8">
      <v-col cols="12" sm="6" class="pa-0 pr-sm-3 mt-8 mt-sm-0">
        <v-card elevation="0" class="d-flex flex-column pa-5 pa-md-10">
          <v-card-title>{{ $t("register.user.cardYes.title") }}</v-card-title>
          <v-card-text class="text-body-0 primary--text font-weight-normal">{{
            $t("register.user.cardYes.text")
          }}</v-card-text>
          <div>
            <v-img
              class="my-4 tigros-card-img mx-auto"
              src="/img_layout/card.png"
              contain
            ></v-img>
          </div>
          <v-btn
            class="mt-auto"
            color="primary"
            v-html="$t('register.user.cardYes.button')"
            :to="{
              name: 'RegistrationCard',
              params: {
                isRegisterWithCard: true
              }
            }"
          /> </v-card
      ></v-col>

      <v-col cols="12" sm="6" class="pa-0 pl-sm-3"
        ><v-card
          elevation="0"
          class="d-flex flex-column pa-5 pa-md-10 mt-8 mb-12 my-sm-0"
        >
          <v-card-title>{{ $t("register.user.cardNo.title") }}</v-card-title>
          <v-card-text class="text-body-0 primary--text font-weight-normal">{{
            $t("register.user.cardNo.text")
          }}</v-card-text>
          <v-btn
            block
            x-large
            outlined
            depressed
            @click="googleLogin"
            class="d-flex flex-row mb-4"
          >
            <v-img
              height="20"
              max-width="20"
              alt="logo-google"
              src="/img_layout/logo-google.svg"
            />
            <span class="primary--text ml-2 text-uppercase">Google</span>
          </v-btn>
          <!-- <v-btn
            block
            x-large
            depressed
            @click="facebookLogin"
            class="d-flex flex-row btn-facebook"
          >
            <v-img
              height="20"
              max-width="20"
              alt="logo-facebook"
              src="/img_layout/logo-facebook.svg"
            />
            <span class="ml-2 text-uppercase">Facebook</span>
          </v-btn> -->

          <div class="separator">
            <span class="line" />
            <span class="text" v-html="$t('common.or')" />
            <span class="line" />
          </div>

          <v-btn
            class="mt-auto"
            color="primary"
            v-html="$t('register.user.cardNo.button')"
            :to="{
              name: 'RegistrationNoCard',
              params: { type: 'NewUser', isRegisterWithCard: false }
            }"
          /> </v-card
      ></v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.registration-user-container {
  color: var(--v-primary-base);
  h1 {
    font-size: 50px !important;
  }
  .description {
    font-size: 20px;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    height: 100%;
    .v-card__title {
      font-size: 30px;
      word-break: break-word;
      line-height: 42px;
    }
    .tigros-card-img {
      max-width: 100%;
      height: 150px;
    }

    .btn-facebook {
      background: #3c5898;
      color: white;
    }

    .separator {
      display: flex;
      align-items: baseline;
      margin: 30px 0;
      .line {
        flex-grow: 1;
        border-top: 1px solid gray;
        height: 5px;
        margin: 0 5px;
      }
      .text {
        font-size: 16px;
        color: var(--v-primary-base);
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 16px !important;
    }
    .v-card {
      height: auto;
    }
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import SocialService from "~/service/socialService";
import categoryMixins from "~/mixins/category";

export default {
  name: "RegistrationUser",
  data() {
    return {
      isSocialLogin: false
    };
  },
  mixins: [categoryMixins],
  components: { CategoryTitle },
  methods: {
    googleLogin() {
      if (this.isCordova) {
        let redirectBaseUrl =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : "");

        let url =
          "https://accounts.google.com/o/oauth2/auth?client_id=" +
          global.config.googleClientId +
          "&redirect_uri=" +
          redirectBaseUrl +
          "/ebsn/api/social-redir/google&prompt=consent&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

        SocialService.handleGoogleOnApp(url, "registration-nocard");
      } else {
        SocialService.openGoogleLoginPage();
      }
    },
    facebookLogin() {
      SocialService.openFacebookLoginPage();
    }
  }
};
</script>
